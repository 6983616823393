.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.accordion-style .accordion-second-title {
  color: #1CAD5E;
}

.accordion-style .MuiAccordionDetails-root {
  background-color: #F8F9FB;
}

.accordion-style .MuiAccordionDetails-root ul {
  padding-left: 20px;
}

.accordion-style .MuiAccordionDetails-root ul li {
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
}

.accordion-style .MuiAccordionDetails-root ul li ol li {
  font-weight: normal;
}

.accordion-style .note-style {
  display: flex;
  align-items: center;
  font-style: italic;
  margin-right: 5px;
  color: #9094A9;
}

.accordion-style .note-style .MuiSvgIcon-root {
  color: #FF6E00;
}

.css-xuaqpw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  z-index: 1201;
}