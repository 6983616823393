html{
  font-size: 14px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTablePagination-input {
  margin-top: 5px;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within{
  outline: none !important;
}

.MuiInput-underline:after
.MuiInput-underline:before {
  border: 0 !important;
}

/* .MuiPickersModal-dialogRoot .MuiButton-label{
  color: #1CAD5E !important
} */
.MuiPickersModal-dialogRoot .MuiDialogActions-root button:first-child {
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.MuiPickersModal-dialogRoot .MuiDialogActions-root button:first-child .MuiButton-label {
  color: #9094A9;
}

.MuiPickersModal-dialogRoot .MuiDialogActions-root button:last-child {
  background-color: #1CAD5E;
}
.MuiPickersModal-dialogRoot .MuiDialogActions-root button:last-child .MuiButton-label {
  color: #FFFFFF;
}

.MuiPickersDay-daySelected{
  color: #fff !important;
}

.MuiDataGrid-root {
  border: 0px !important;
}

/* .MuiTooltip-tooltip {
  background-color: '#6C6C6C';
  color: '#FDFFFF';
  font-size: 20;
} */

